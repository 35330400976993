<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Marketing</h2>
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Marketing Opt In</th>
            <td>
              <v-switch
                inset
                v-model="customer.marketing_opt_in"
                :label="customer.marketing_opt_in ? 'Yes' : 'No'"
                color="green"
                :loading="loaders.marketing"
                @click="updateMarketingOptIn"
              ></v-switch>
            </td>
          </tr>
          <tr>
            <th>Referred By</th>
            <td class="pt-4 pb-4">
              <v-select
                label="Referred By"
                :items="customers"
                v-model="referred_by_id"
                item-value="id"
                item-text="full_name"
                outlined
                hide-details
                dense
                background-color="white"
                clearable
                :loading="loaders.referred_by"
              ></v-select>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loaders: {
        marketing: false,
        referred_by: false,
      },
      referred_by_id: null,
    };
  },

  computed: {
    customers() {
      let customers = this.$store.state.drum.customers["customers"];

      return customers.filter((c) => {
        return c.id !== this.customer.id;
      });
    },
  },

  created() {
    this.referred_by_id = this.customer.referred_by_id;
  },

  watch: {
    referred_by_id(newValue) {
      this.updateReferredBy(newValue);
    },
  },

  methods: {
    updateMarketingOptIn: function () {
      const appId = this.$route.params.id;
      this.loaders.marketing = true;

      this.$store
        .dispatch("drum/customers/saveCustomer", {
          appId,
          isEditing: true,
          customerId: this.customer.id,
          fields: {
            marketing_opt_in: this.customer.marketing_opt_in,
          },
        })
        .then(() => (this.loaders.marketing = false))
        .catch(() => {
          this.loaders.marketing = false;
          this.customer.marketing_opt_in = !this.customer.marketing_opt_in;
        });
    },
    updateReferredBy: function (newValue) {
      const appId = this.$route.params.id;
      this.loaders.referred_by = true;

      this.$store
        .dispatch("drum/customers/saveCustomer", {
          appId,
          isEditing: true,
          customerId: this.customer.id,
          fields: {
            referred_by_id: newValue,
          },
        })
        .then(() => (this.loaders.referred_by = false))
        .catch(() => {
          this.loaders.referred_by = false;
          this.referred_by_id = this.customer.referred_by_id;
        });
    },
  },
};
</script>